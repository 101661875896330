.freeShippingOverContainer {
  margin-bottom: 0.4rem;
}
.freeShippingBadge {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  composes: ui11Medium from 'styles/typeography.css';
  color: var(--colorGray11);
  background-color: var(--colorTan3) !important;
}

/* Width of catalog cover image - 228.5px */
@media (min-width: 228.5px) {
  .freeShippingBadge {
    white-space: normal;
  }
}
